import React from 'react'
import { Link } from 'gatsby'
import Fade from "react-reveal/Fade"

import { useSelector, useDispatch } from 'react-redux'
import { newsDetailSlug } from '../actions'

const MasonryItem =(props) => {
    const langKey = useSelector(state => state.langKey.language)
    let urlBase
    if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
        urlBase = langKey === "ko" ? "" : `/${langKey}`
    }
    else{
        urlBase = langKey === "ja" ? "" : `/${langKey}`
    }
    const dispatch = useDispatch()    
    return(
       
            <div className="col-12 col-lg-4 blogs__items">
                <Fade bottom speed={450} delay={25} distance="15%">
                    <div className="blogs__items__box">
                        <Link to={`${urlBase}/${props.section}/${props.url}`} onClick={()=> dispatch(newsDetailSlug(props.url))} >
                        <div className="blogs__items__box__image">
                        <img src={props.thumb} srcSet={props.thumbSrcSet} alt="" />
                        </div>
                        <div className="blogs__items__box__content">
                            <h3 dangerouslySetInnerHTML={{__html:props.title}}></h3>
                            <p dangerouslySetInnerHTML={{__html:props.assert}}></p>
                        </div>
                        </Link>
                    </div>
                </Fade>
            </div>

    )
}

export default MasonryItem