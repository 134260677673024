import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import Layout from "../layouts/App"
import SEO from "../components/seo"
// import Fade from "react-reveal/Fade"

// import Spotlight from '../components/Spotlight'
import MasonryItem from '../components/MasonryItem'

const masonryOptions = {
    transitionDuration: '0.6s'
};

export default ({pageContext}) => (
    <Layout>
        <SEO title="News" description={pageContext.node.acf.metaPageDescription}/>
        <article className="blogs">
            {/* <Fade bottom distance={`100px`}>
                <Spotlight image={pageContext.node.acf.spotlight_image.source_url} />
            </Fade> */}
            <section className="container-fluid-custom-only content-body top__margin">
                <StaticQuery query={graphql`
                    {
                        newsJA: allWpNews(filter:{languageCode:{eq:"ja"}, related_office: {relatedOffice: {ne: "wkseoul"}}} sort: { fields: [date], order: DESC }){
                                edges{
                                    node{
                                        id
                                        title
                                        slug
                                        languageCode
                                        acf {
                                            newsExcerpt
                                            newsImage {
                                                id
                                                sourceUrl
                                                srcSet
                                                mediaDetails {
                                                    sizes {
                                                      name
                                                      sourceUrl
                                                    }
                                                  }
                                            }
                                        }
                                        related_office {
                                            relatedOffice
                                        }
                                    }
                                }
                        }
                     newsEN: allWpNews(
                         filter:{languageCode:{eq:"en"}} sort: { fields: [date], order: DESC }
                         ){
                            edges{
                                node{
                                    id
                                    title
                                    slug
                                    languageCode
                                    acf {
                                        newsExcerpt
                                        newsImage {
                                            id
                                            sourceUrl
                                            srcSet
                                            mediaDetails {
                                                sizes {
                                                  name
                                                  sourceUrl
                                                }
                                              }
                                        }
                                    }
                                    related_office {
                                        relatedOffice
                                    }
                                }
                            }
                        }
                    
                        newsKO: allWpNews(filter:{languageCode:{eq:"ko"}, related_office: {relatedOffice: {ne: "wktokyo"}}} sort: { fields: [date], order: DESC }){
                            edges{
                                node{
                                    id
                                    title
                                    slug
                                    languageCode
                                    acf {
                                        newsExcerpt
                                        newsImage {
                                            id
                                            sourceUrl
                                            srcSet
                                            mediaDetails {
                                                sizes {
                                                  name
                                                  sourceUrl
                                                }
                                              }
                                        }
                                    }
                                    related_office {
                                        relatedOffice
                                    }
                                }
                            }
                        }
                    }
                `} render={ props => {
                    if(pageContext.node.languageCode === 'ja'){
                        let newsImagery
                        return(
                            <div className={'grid__layout'} options={masonryOptions}>
                            {props.newsJA.edges.map(news =>{
                                {news.node.acf.newsImage.mediaDetails.sizes.map(imageSrc => {
                                    for(let i=0; i<5; i++){
                                        if(imageSrc.name === 'large'){
                                            return newsImagery = imageSrc.sourceUrl
                                            i = 4;
                                        }else if(imageSrc.name === 'medium') {
                                            return newsImagery = imageSrc.sourceUrl
                                            i = 4;
                                        }  
                                        i++;                                             
                                    }
                                })}
                                return(
                                    <MasonryItem 
                                        key={news.node.id}
                                        section="news"
                                        url={news.node.slug} 
                                        image={newsImagery}
                                        thumbSrcSet={news.node.acf.newsImage.srcSet}
                                        title={news.node.title}
                                        assert={news.node.acf.newsExcerpt} 
                                    />
                                )
                            })}
                            </div>
                        )
                    }
                    if(pageContext.node.languageCode === 'en'){
                        let newsImagery
                        return(
                            <div className={'grid__layout'} options={masonryOptions}>
                            {props.newsEN.edges.map(news =>{
                                if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
                                    if(news.node.related_office.relatedOffice !== 'wktokyo'){
                                        {news.node.acf.newsImage.mediaDetails.sizes.map(imageSrc => {
                                            for(let i=0; i<5; i++){
                                                if(imageSrc.name === 'large'){
                                                    return newsImagery = imageSrc.sourceUrl
                                                    i = 4;
                                                }else if(imageSrc.name === 'medium') {
                                                    return newsImagery = imageSrc.sourceUrl
                                                    i = 4;
                                                }  
                                                i++;                                             
                                            }
                                        })}
                                        return(
                                            <MasonryItem 
                                                key={news.node.id}
                                                section="news"
                                                url={news.node.slug} 
                                                image={newsImagery}
                                                thumbSrcSet={news.node.acf.newsImage.srcSet}
                                                title={news.node.title}
                                                assert={news.node.acf.newsExcerpt} 
                                            />
                                        )
                                    }
                                }
                                else{
                                    if(news.node.related_office.relatedOffice !== 'wkseoul'){
                                        {news.node.acf.newsImage.mediaDetails.sizes.map(imageSrc => {
                                            for(let i=0; i<5; i++){
                                                if(imageSrc.name === 'large'){
                                                    return newsImagery = imageSrc.sourceUrl
                                                    i = 4;
                                                }else if(imageSrc.name === 'medium') {
                                                    return newsImagery = imageSrc.sourceUrl
                                                    i = 4;
                                                }  
                                                i++;                                             
                                            }
                                        })}
                                        return(
                                            <MasonryItem 
                                                key={news.node.id}
                                                section="news"
                                                url={news.node.slug} 
                                                image={newsImagery}
                                                thumbSrcSet={news.node.acf.newsImage.srcSet}
                                                title={news.node.title}
                                                assert={news.node.acf.newsExcerpt} 
                                            />
                                        )
                                    }
                                }
                            })}
                            </div>
                        )
                    }
                    if(pageContext.node.languageCode === 'ko'){
                        let newsImagery
                        return(
                            <div className={'grid__layout'} options={masonryOptions}>
                            {props.newsKO.edges.map(news =>{
                                {news.node.acf.newsImage.mediaDetails.sizes.map(imageSrc => {
                                    for(let i=0; i<5; i++){
                                        if(imageSrc.name === 'large'){
                                            return newsImagery = imageSrc.sourceUrl
                                            i = 4;
                                        }else if(imageSrc.name === 'medium') {
                                            return newsImagery = imageSrc.sourceUrl
                                            i = 4;
                                        }  
                                        i++;                                             
                                    }
                                })}
                                return(
                                    <MasonryItem 
                                        key={news.node.id}
                                        section="news"
                                        url={news.node.slug} 
                                        image={newsImagery}
                                        thumbSrcSet={news.node.acf.newsImage.srcSet}
                                        title={news.node.title}
                                        assert={news.node.acf.newsExcerpt} 
                                    />
                                )
                            })}
                            </div>
                        )
                    }
                }} />
            </section>
        </article>
    </Layout>
)